<template>
    <div>
      <div class='title-row'>
        <span class='title'>MCN团队奖金</span>
      </div>
      <div style="width: 100%">
        <div style='display: inline-block'>
          <div style='padding-left: 10px'>
            <div v-if="userPermissions.indexOf('dept_mcn_bonus')>-1">
              <div>
                <el-row>
                  <el-col :span='24'>
                    <el-form size='mini' inline>
                      <el-form-item label='年份'>
                        <el-date-picker
                            v-model='year'
                            type='year'
                            format='yyyy年'
                            value-format='yyyy'
                            placeholder='选择月度' style='width: 140px' @change='getBonusList'>
                        </el-date-picker>
                      </el-form-item>
                      <el-form-item>
                        <el-button type='primary' @click='getBonusList'>查询</el-button>
                      </el-form-item>
                    </el-form>
                  </el-col>
                </el-row>
                <!--              <h2 @click="loadDeptInfo">部门：{{ deptInfo.name || '&#45;&#45;' }}</h2>-->
                <div v-for='groupData in groupBonusData' :key='groupData.id'>
                  <DeptMcnBonusView :group-data='groupData' />
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>


    </div>
</template>

<script>

import BonusItemsTable from '@/pages/performance/bonus/BonusItemsTable'
import DeptMcnBonusView from '@/pages/departments/components/DeptMcnBonusView'
import SearchCondition from "@/pages/team/components/SearchCondition";
import { mapGetters } from 'vuex'

export default {
  name: 'McnBonusView',
  components: { DeptMcnBonusView, BonusItemsTable, SearchCondition},
  computed:{
    ...mapGetters(['userPermissions']),
  },
  data() {
    return {
      dept_id: null,
      year: null,
      ym: null,
      deptInfo: {},
      groupBonusData: [],
      pageData: { current_page: 1, page_size: 15 },
      //有权限查看的项目组
      allowGroups:[]
    }
  },
  props: {
    condition: {
      type: Object,
      default() {
        return { year: null, dept_id: null }
      }
    }
  },
  watch: {
    condition: {
      immediate: true,
      deep: true,
      handler(val) {
        this.year=val.year
        this.dept_id=val.dept_id
        // console.log(val)
        this.loadDeptInfo()
        this.getBonusList()
      }
    }
  },
  methods: {
    async loadDeptInfo() {
      if(this.condition.dept_id){
        let { info } = await this.$api.getDeptInfo(this.condition.dept_id)
        this.deptInfo = info
      }

    },
    async getBonusList() {
      // console.log(this.condition)
      this.groupBonusData = []
      // const dateArr = this.ym.split('-')
      if (this.year&& this.condition.dept_id) {
        let params = Object.assign({
          year: this.year,
          // month: dateArr[1],
          group_id: this.condition.dept_id
        }, this.pageData)

        let { list, page_info } = await this.$api.getDeptMcnBonusList(params)
        this.groupBonusData = list
        this.pageData.current_page = page_info.current_page
        this.pageData.page_size = page_info.page_size
      }

    }

  },
  mounted() {
    // this.loadDeptInfo()
    // this.getBonusList()
  },
  created() {
    // //this.dept_id = this.$route.params.id ?? this.condition.dept_id
    // this.dept_id = this.condition.group_id
    // // 默认上个月
    // //this.year = new Date().getFullYear() + '' ?? this.condition.year
    // this.year = this.condition.year
    // // this.ym = this.$utils.getLastMonth()
    //
    // console.log(this.dept_id, this.year)

  }
}
</script>

<style scoped>
/*.title-row {*/
/*  padding-left: 10px;*/
/*  margin-bottom: 10px;*/
/*}*/

.title {
  font-size: 16px;
  font-weight: 800;
  float: left;
  line-height: 30px;
  padding-right: 20px;
}
</style>
