<template>
  <div>
    <PageHeaderLayout>
      <div class="main-page-content">
        <el-form label-width="68px">
          <el-row :gutter="15">
            <el-col :lg="10" :md="24" :xs="24">
              <el-card class="panel">
                <div slot="header">
                  <span>平台类型（ 平台/代号：{{ platform.name }} / {{ platform.code }}）</span>
                  <el-button style="float: right; padding: 3px 0" type="text" @click="saveSetting('type')"
                             :disabled="loading"
                             :loading="loading">保存
                  </el-button>
                </div>
                <div>
                  <el-row>
                    <el-col :span="24">
                      <el-radio-group v-model="formData.type">
                        <el-radio :label="item.val" :value="item.val" v-for="(item,index) in typeMap" :key="index">
                          {{ item.title }}
                        </el-radio>
                      </el-radio-group>
                    </el-col>
                  </el-row>
                </div>
              </el-card>

              <el-card class="panel">
                <div slot="header">
                  <span>工作薄配置（ 平台/代号：{{ platform.name }} / {{ platform.code }}）</span>
                  <el-button style="float: right; padding: 3px 0" type="text" @click="saveLogoSetting"
                             :disabled="loading"
                             :loading="loading">保存
                  </el-button>
                </div>
                <div>
                  <el-form :model="logoSetting" inline>

                    <el-form-item label="">
                      平台Logo显示在第
                      <el-input placeholder="Logo图标摆放列，例如：E" v-model="logoSetting.cellCol" clearable
                                style="width: 80px"/>
                      列，
                    </el-form-item>
                    <el-form-item label="">
                      第
                      <el-input placeholder="Logo图标摆放行，例如：1" v-model="logoSetting.cellRow" clearable
                                style="width: 80px"/>
                      行
                    </el-form-item>
                    <el-form-item label="">
                      X轴偏移
                      <el-input placeholder="X轴偏移量" v-model="logoSetting.offsetX" clearable style="width: 80px"/>
                    </el-form-item>
                    <el-form-item label="">
                      Y轴偏移
                      <el-input placeholder="Y轴偏移量" v-model="logoSetting.offsetY" clearable style="width: 80px"/>
                    </el-form-item>
                    <el-form-item label="宽度">
                      <el-input placeholder="宽度" v-model="logoSetting.width" clearable style="width: 80px"/>
                    </el-form-item>
                    <el-form-item label="高度">
                      <el-input placeholder="高度" v-model="logoSetting.height" clearable style="width: 80px"/>
                    </el-form-item>

                  </el-form>
                </div>
              </el-card>
              <el-card class="panel">
                <div slot="header">
                  <span>表头设置（ 平台/代号：{{ platform.name }} / {{ platform.code }}）</span>
                  <el-button style="float: right; padding: 3px 0" type="text" @click="saveTableHeader"
                             :disabled="loading"
                             :loading="loading">保存
                  </el-button>
                </div>
                <div>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="背景色">
                        <el-color-picker
                            v-model="formData.bg_color"
                            show-alpha
                            :predefine="predefineColors">
                        </el-color-picker>
                      </el-form-item>
                      <el-form-item label="字颜色">
                        <el-color-picker
                            v-model="formData.font_color"
                            show-alpha
                            :predefine="predefineColors">
                        </el-color-picker>
                      </el-form-item>
                      <el-form-item label="行高">
                        <el-input type="text" placeholder="表格行高(单位默认：pt(points 磅值)" v-model="formData.header_height"/>
                        <p style="font-size: 0.8em;">
                          Valid units are pt (points 磅值), px (pixels), pc (pica), in (inches), cm (centimeters) and mm
                          (millimeters).
                        </p>
                      </el-form-item>

                    </el-col>
                  </el-row>
                </div>
              </el-card>
              <el-card class="panel">
                <div slot="header">
                  <span>第一部分-常规字段（ 平台/代号：{{ platform.name }} / {{ platform.code }}）</span>
                  <el-button style="float: right; padding: 3px 0" type="text" @click="saveSetting('common_fields')"
                             :disabled="loading"
                             :loading="loading">保存
                  </el-button>
                </div>
                <div>
                  <el-row>
                    <el-col :span="24">
                      <el-checkbox-group v-model="formData.common_fields">
                        <el-checkbox @change="forceUpdate(extra_col)" :label="extra_col.field" :value="extra_col.field"
                                     v-for="(extra_col,index) in common_cols" :key="index">{{ extra_col.title }}
                        </el-checkbox>
                      </el-checkbox-group>
                    </el-col>
                  </el-row>
                </div>
              </el-card>
              <el-card class="panel">
                <div slot="header">
                  <span>第二部分-报价字段（ 平台/代号：{{ platform.name }} / {{ platform.code }}）</span>
                  <el-button style="float: right; padding: 3px 0" type="text" @click="saveSetting('platform_quote')"
                             :disabled="loading"
                             :loading="loading">保存
                  </el-button>
                </div>
                <div>
                  <el-table class="tbl_quote" row-key="field" ref="tbl_quote" :data="formData.platform_quote">
                    <el-table-column label="报价字段" prop="field"></el-table-column>
                    <el-table-column label="报价标题" prop="title">
                      <template slot-scope="scope">
                        <p v-html="scope.row.title"></p>
                      </template>
                    </el-table-column>

                    <el-table-column label="操作" align="center">
                      <template slot-scope="scope">
                        <el-button type="text" @click="editRow(scope.row,scope.$index)"><i class="el-icon-edit"></i>编辑
                        </el-button>
                        <el-button type="text" @click="delRow(scope.row,scope.$index)"><i class="el-icon-remove"></i>删除
                        </el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                  <el-divider/>
                  <div class="form-card">
                    <el-form :inline="false" :model="formQuote" label-width="100px">
                      <el-form-item label="报价字段">
                        <el-input v-model="formQuote.field"></el-input>
                      </el-form-item>
                      <el-form-item label="报价标题">
                        <el-input type="textarea" rows="2" v-model="formQuote.title"></el-input>
                      </el-form-item>
                      <el-form-item label="占位提示">
                        <el-input v-model="formQuote.placeholder"></el-input>
                      </el-form-item>
                      <el-form-item>
                        <el-button @click="addRow" type="primary" v-if="currentIndex==-1">添加</el-button>
                        <el-button @click="saveRow" type="primary" v-else>保存</el-button>
                        <el-button @click="resetRow" type="info">重置</el-button>
                      </el-form-item>
                    </el-form>
                  </div>
                </div>
                <div class="demo-cards" style="height: 400px;overflow-y: scroll">
                  <div v-for="(item,platform_code) in quoteMap" :key="platform_code"
                       style="border-radius: 20px;margin-bottom: 20px" class="demo-card">
                    <el-link @click="selectDemo(item.cols)" class="demo-select">{{ platform_code }}<i
                        class="el-icon-connection"></i>
                    </el-link>
                    <el-table :data="item.cols">
                      <el-table-column label="报价字段" prop="field"></el-table-column>
                      <el-table-column label="报价标题" prop="title"></el-table-column>
                    </el-table>
                  </div>
                </div>
              </el-card>

            </el-col>
            <el-col :lg="14" :md="24" :xs="24">
              <el-card class="panel">
                <div slot="header">
                  <span>第三部分-其他字段（ 平台/代号：{{ platform.name }} / {{ platform.code }}）</span>
                  <el-button style="float: right; padding: 3px 0" type="text" @click="saveSetting('extra_fields')"
                             :disabled="loading"
                             :loading="loading">保存
                  </el-button>
                </div>
                <div>
                  <el-row>
                    <el-col :span="24">
                      <el-checkbox-group v-model="formData.extra_fields">
                        <el-checkbox @change="forceUpdate" :label="extra_col.field" :value="extra_col.field"
                                     v-for="(extra_col,index) in extra_cols" :key="index">{{ extra_col.title }}
                        </el-checkbox>
                      </el-checkbox-group>
                    </el-col>
                  </el-row>
                </div>
              </el-card>

              <el-card class="panel">
                <div slot="header">
                  <span>表格列定义（ 平台/代号：{{ platform.name }} / {{ platform.code }}）</span>
                  <el-button style="float: right; padding: 3px 0" type="text" @click="saveSetting('columns_data')"
                             :disabled="loading"
                             :loading="loading">保存
                  </el-button>
                </div>
                <div>
                  <PlcColumnsTable :table-data.sync="formData.columns_data"></PlcColumnsTable>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </el-form>

      </div>
    </PageHeaderLayout>
    <PlcFieldDialog :visible.sync="dialogVisible">

    </PlcFieldDialog>
  </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import {scrollTo} from '@/utils/scroll-to'
import EditableCell from "@/components/EditableCell";
import PlcFieldDialog from "@/pages/platform/components/PlcFieldDialog"
import Sortable from 'sortablejs'
import PlcColumnsTable from "@/pages/platform/PlcColumnsTable";

const {deepClone} = require("@/utils");

export default {
  name: "PlcSet",
  components: {
    PlcColumnsTable,
    PageHeaderLayout, EditableCell, PlcFieldDialog
  },
  data() {
    return {
      platform_id: null,
      platform: {},
      loading: false,
      formData: {
        type: 'ONLINE',
        bg_color: '#000000',
        font_color: '#FFFFFF',
        header_height: '31.5pt',//表格行高
        common_fields: [],//常规字段
        platform_quote: [],//平台报价字段
        extra_fields: [],//其他字段
        columns_data: [],//列自定义数据
        work_sheet: {
          logoSetting: {
            cellCol: 'E',
            cellRow: 1,
            width: 50,
            height: 50,
            offsetX: 10,
            offsetY: 10
          }
        }
      },
      logoSetting: {
        cellCol: 'E',
        cellRow: 1,
        width: 50,
        height: 50,
        offsetX: 10,
        offsetY: 10
      },
      extra_fields: [],
      formQuote: {title: '', field: 'quote_',placeholder:'请输入下月预估价格'},
      currentIndex: -1,
      quoteMap: {},
      typeMap: [
        {title: '线上平台', val: 'ONLINE'},
        {title: '线下平台', val: 'OFFLINE'},
        {title: '抖音+直播打包', val: 'DL'},
      ],
      common_cols: [
        {title: '序号', field: 'index'},
        {title: '头像', field: 'cover_pc_url', type: 'img'},
        {title: '昵称', field: 'nickname'},
        {title: '简介', field: 'intro'},
        {title: '城市', field: 'city'},
        {title: '粉丝（万）', field: 'fans_total'},
        {title: '微博粉丝（万）', field: 'weibo_fans_count'},
        {title: '抖音粉丝（万）', field: 'douyin_fans_count'},
        {title: '小红书粉丝（万）', field: 'xiaohongshu_fans_count'},
        {title: 'B站粉丝（万）', field: 'bilibili_fans_count'},
        {title: '快手粉丝（万）', field: 'kuaishou_fans_count'},
        {title: '视频号粉丝（万）', field: 'weixin_vedio_fans_count'},
        {title: '赞藏数（万）', field: 'zc_total'},
        {title: '直播时间', field: 'lb_time'},
        {title: '直播案例', field: 'lb_case'},
        {title: '女粉比例', field: 'female_fans_ratio'},
        {title: '平均在线', field: 'avg_online'},
        {title: '品类', field: 'kind'},
        {title: '场次', field: 'sessions'},
        {title: '直播费用', field: 'lb_fee'},
        {title: '商业化营销中心终价', field: 'quote'},//报价
        {title: '标签', field: 'kol_label'},//报价
        {title: '肤质', field: 'skin_type'},//报价

      ],
      extra_cols: [
        {title: '平台ID', field: 'account_id'},
        {title: '账号主页', field: 'home_page_link'},
        {title: '是否开通星图', field: 'opened_star'},
        {title: '是否开通橱窗', field: 'opened_window'},
        {title: '是否为品牌合作人', field: 'is_partner'},
        {title: '是否开通购物车', field: 'opened_cart'},
        {title: '商务案例', field: 'bs_case'},
      ],

      predefineColors: [
        'rgba(0,0,0,1)',
        'rgba(255,0,59,1)',
        'rgba(255,182,0,1)',
        'rgba(0,158,239,1)',
        'rgba(255,78,0,1)',
        'rgba(179,0,0,1)',
        'rgba(255,255,250,1)',
        'rgba(255,255,200,1)',
      ],
      columns: [],//表格列表配置
      //excel表格配置
      excelDataList: [],
      dialogVisible: false,
    }
  },
  methods: {
    insertColumn(item, rangeArr) {
      let index = this.formData.columns_data ? this.formData.columns_data.findIndex(value => value.field === item.field) : -1
      // console.log('index',index)
      // let rowOrg = this.formData.columns_data[index];
      let row = {field: item.field, title: item.title, height: '80.1', width: '15', horizontal: 'center'}
      // Object.assign(row, rowOrg)//合并数据
      if (rangeArr.indexOf(item.field) > -1 && index === -1)
        this.formData.columns_data.push(row)
    },
    calcColumns() {
      // this.formData.columns_data = []
      //常规字段
      this.common_cols.forEach((item) => {
        this.insertColumn(item, this.formData.common_fields)
      })
      //报价字段
      this.formData.platform_quote.forEach((item) => {
        // console.log(item)
        this.insertColumn(item, [item.field])
      })
      //额外字段
      this.extra_cols.forEach((item) => {
        this.insertColumn(item, this.formData.extra_fields)
      })
    },
    delRow(row, index) {
      this.formData.platform_quote.splice(index, 1)
      this.calcColumns()
    },
    // eslint-disable-next-line no-unused-vars
    editRow(row, index) {
      this.formQuote = deepClone(row)
      this.currentIndex = index
      this.calcColumns()
    },
    saveRow() {
      this.formData.platform_quote[this.currentIndex]['field'] = this.formQuote.field
      this.formData.platform_quote[this.currentIndex]['title'] = this.formQuote.title
      this.formData.platform_quote[this.currentIndex]['placeholder'] = this.formQuote.placeholder

      this.resetRow()
      this.calcColumns()
    },
    addRow() {
      this.formData.platform_quote.push({...deepClone(this.formQuote)})
      this.resetRow()
      this.calcColumns()
    },
    resetRow() {
      this.currentIndex = -1
      let index = this.formData.platform_quote ? this.formData.platform_quote.length + 1 : 1
      this.formQuote = {title: '', field: `quote_${index}`}
    },
    /**
     * 获取配置
     * @returns {Promise<void>}
     */
    async getSetting() {
      let {quote_setting} = await this.$api.getPublicationSetting({quote_type: 'platform_quote'});
      this.quoteMap = quote_setting
    },
    selectDemo(rows) {
      //选用案例
      this.formData.platform_quote = deepClone(rows)
      this.calcColumns()
      // this.formData.platform_quote = []
      // rows.forEach(( row ) => {
      //   this.formData.platform_quote.push(deepClone(row))
      // })
      // this.$nextTick(() => {
      //   this.$refs['tbl_quote'].doLayout()
      // })
      // this.$forceUpdate()
      scrollTo(0, 800)
    },
    //复选框勾选后触发
    forceUpdate() {
      this.calcColumns()//重新计算表格列
      this.$forceUpdate()
    },
    async savePlcSetting() {
      await this.saveSetting('platform_quote')
    },
    async saveExtra() {
      await this.saveSetting('extra_fields')
    },
    async saveTableHeader() {
      await this.saveSetting(['bg_color', 'font_color', 'header_height'])
    },
    async saveLogoSetting() {
      let data = {
        platform_id: this.platform_id,
        work_sheet: {logoSetting: this.logoSetting}
      }
      this.loading = true
      let info = await this.$api.savePlatformSetting(data)
      if (info) {
        this.$notify.success('保存成功')
      }
      this.loading = false
    },
    async saveSetting(field) {
      let data = {
        platform_id: this.platform_id,
      }
      if (typeof field === 'string')
        data[field] = this.formData[field]
      else if (typeof field === 'object') {
        field.forEach((f) => {
          data[f] = this.formData[f]
        })
      }

      this.loading = true
      let info = await this.$api.savePlatformSetting(data)
      if (info) {
        this.$notify.success('保存成功')
      }
      this.loading = false
    },
    async getPlatformInfo() {
      let {info} = await this.$api.getPlatformInfo(this.platform_id)
      if (info) {
        this.platform = info
      }
    },
    reset() {
      this.formData = {
        platform_quote: [],
        extra_fields: [],
        common_fields: [],
        columns_data: [],
        bg_color: 'rgba(0, 0, 0,1)',
        font_color: 'rgba(255, 255, 255, 1)',
        type: 'ONLINE'
      }
      this.resetRow()
    },
    async getPlatformSettingInfoByPlatformId() {
      this.reset()
      let {info} = await this.$api.getPlatformSettingInfoByPlatformId(this.platform_id)
      if (info && info.id) {
        this.formData = info || {}
        //数据类型要保证正确，否则可能渲染失败（MAC电脑上）(数据类型不对，确保数据类型正确，手动处理)
        this.formData.platform_quote = info.platform_quote || []
        this.formData.extra_fields = info.extra_fields || []
        this.formData.common_fields = info.common_fields || []
        this.formData.columns_data = info.columns_data || []
        this.formData.work_sheet = info.work_sheet || {}
        this.formData.type = info.type || 'ONLINE'
        if (info.work_sheet)
          this.logoSetting = info.work_sheet.logoSetting || {}
        this.resetRow()
        this.calcColumns()
        this.$nextTick(() => {
          this.rowDrop()
        })
      }
    },
    //行拖拽
    rowDrop() {
      const tbody = document.querySelector('.tbl_quote > .el-table__body-wrapper tbody')
      const _this = this
      Sortable.create(tbody, {
        onEnd({newIndex, oldIndex}) {
          const currRow = _this.formData.platform_quote.splice(oldIndex, 1)[0]
          _this.formData.platform_quote.splice(newIndex, 0, currRow)
        }
      })
    },
  },
  mounted() {

  },
  created() {
    this.platform_id = this.$route.params.platform_id
    this.getPlatformInfo()
    this.getSetting()
    this.getPlatformSettingInfoByPlatformId()
  }
}
</script>

<style scoped>
.panel {
  margin-top: 10px;
}

.demo-cards {
  padding: 20px 40px;
  background-color: #DCDFE6;
}

.demo-select {
  font-size: 1rem;
  margin-bottom: 10px;
  font-weight: 800;
  color: #ff3176;
}

.demo-card {
  border-radius: 10px;
}

.form-card {
  border: #DCDFE6 1px solid;
  border-radius: 10px;
  padding: 10px 10px;
  text-align: center;
  vertical-align: middle;
  margin-bottom: 10px;
}
</style>
