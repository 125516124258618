<template>
  <el-form :model='searchCondition' inline size='mini'>
    <el-form-item>
      <el-date-picker
        style='width: 160px'
        clearable
        format='yyyy年'
        placeholder='选择年'
        type='year'
        v-model='searchCondition.year' value-format='yyyy'>
      </el-date-picker>
    </el-form-item>
    <el-form-item>
      <QuarterSelect :quarter.sync='searchCondition.quarter' size='mini' />
    </el-form-item>

  </el-form>

</template>

<script>
import QuarterSelect from '@/pages/performance/components/QuarterSelect'
import PerformanceGroupSelect from '@/pages/performance/components/PerformanceGroupSelect'
import RevenueHistoryPanel from '@/pages/performance/components/RevenueHistoryPanel'
import ArtistSearch from '@/components/artist/ArtistSearch'

export default {
  name: 'SearchCondition',
  components: { ArtistSearch, RevenueHistoryPanel, PerformanceGroupSelect, QuarterSelect },
  props: {
    searchCondition: {
      type: Object,
      default: () => ({ year: null, group_id: null, group_name: '', quarter: 0, release_status: 'Y' })
    }
  },
  data() {
    return {
      kolOptions: []
      // searchCondition: { year: null, quarter: null, artist_id: null }
    }
  },
  methods: {},
  computed: {
    groupSearch() {
      return { year: this.searchCondition.year, quarter: this.searchCondition.quarter }
    }
  },
  created() {
    this.searchCondition.year = new Date().getFullYear() + ''
    this.searchCondition.quarter = this.$utils.getCurrentQuarter()
    // this.$emit('searchCondition', this.searchCondition)
    // $months = [$quarter * 3 - 2, $quarter * 3 - 1, $quarter * 3];
  }
}
</script>

<style scoped>

</style>
