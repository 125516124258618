<template>
  <div>

    <div class='table-search'>
      <el-form :model='searchCondition' inline size='mini'>
<!--        <el-form-item label='记录Id(角色表的ID)'>-->
<!--          <el-input v-model='searchCondition.info_id' placeholder='记录Id(角色表的ID)' clearable></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item label='操作类型'>-->
<!--          <el-input v-model='searchCondition.op_type' placeholder='操作类型' clearable></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item label='修改内容'>-->
<!--          <el-input v-model='searchCondition.mgs' placeholder='修改内容' clearable></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item label='操作人id'>-->
<!--          <el-input v-model='searchCondition.op_id' placeholder='操作人id' clearable></el-input>-->
<!--        </el-form-item>-->

        <el-form-item label='创建时间'>
          <el-date-picker
            v-model='searchCondition.date_range'
            type='daterange'
            align='right'
            unlink-panels
            range-separator='至'
            start-placeholder='开始日期'
            end-placeholder='结束日期'
            value-format='yyyy-MM-dd'
            :picker-options='pickerOptions'>
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type='primary' icon='el-icon-search' :disabled='loading' :loading='loading' @click='handleQuery'>
            刷新
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <!--    渲染表格-->
    <div class='default-table'>
      <MlPageTable :all-columns='defaultColumns'
                   :table-name='tableName'
                   :data-list='dataList'
                   :limit.sync='pageData.page_size'
                   :page.sync='pageData.current_page'
                   :total.sync='total'
                   :height='height'
                   highlight-current-row
                   :row-class-name='tableRowClassName'
                   @row-click='rowClickEvent'
                   @getList='getList'>
      </MlPageTable>
    </div>

  </div>
</template>

<script>
import MlPageTable from '@/components/common/MlPageTable'
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import ExportBtn from '@/components/export/ExportBtn'


export default {
  name: 'RoleLogList',
  components: { ExportBtn, PageHeaderLayout, MlPageTable },
  props: {
    RoleId: {
      type: [Number, String]
    }
  },
  data() {
    return {
      tableName: 'role_log',
      loading: false,
      loadingCommit: false,
      dataList: [],
      total: 0,
      height: 400,
      searchCondition: { date_range: [], info_id: null, op_type: null, mgs: null, op_id: null },
      pageData: { current_page: 1, page_size: 100 },
      orderBy: { id: 'desc' },
      pickerOptions: {
        shortcuts: [{
          text: '本月',
          onClick(picker) {
            picker.$emit('pick', [new Date(), new Date()])
          }
        }, {
          text: '今年至今',
          onClick(picker) {
            const end = new Date()
            const start = new Date(new Date().getFullYear(), 0)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近六个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setMonth(start.getMonth() - 6)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      defaultColumns: [{ hide: true, prop: 'id', label: '序号', width: 100, sortable: false, fixed: false },
        // { hide: false, prop: 'info_id', label: '记录Id(角色表的ID)', width: 120, sortable: true, fixed: false },
        { hide: true, prop: 'op_type', label: '操作类型', width: 100, sortable: true, fixed: false },
        { hide: false, prop: 'mgs', label: '修改内容', width: 600, sortable: true, fixed: false },
        { hide: true, prop: 'op_id', label: '操作人id', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'created_at', label: '创建时间', width: 120, sortable: false, fixed: false },
        { hide: true, prop: 'updated_at', label: '修改时间', width: 120, sortable: false, fixed: false }],
      // 弹出层标题
      title: '添加/修改-',
      // 是否显示弹出层
      open: false,
      // 表单校验
      rules: {
        info_id: [{ required: true, message: '记录Id(角色表的ID)不能为空', trigger: 'blur' }],
        op_type: [{ required: true, message: '操作类型不能为空', trigger: 'blur' }],
        mgs: [{ required: true, message: '修改内容不能为空', trigger: 'blur' }],
        op_id: [{ required: true, message: '操作人id不能为空', trigger: 'blur' }]
      },
      // 表单参数
      form: {},
      //是否显示弹出层:导入框
      openImport: false,
      //筛选条件缓存key
      pageSearchKey: 'page_search_key:role_log'
    }
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    rowClickEvent(row, column, event) {
      // this.$notify.info(`数据ID：${row.id}`)
    },
    // eslint-disable-next-line no-unused-vars
    tableRowClassName({ row, rowIndex }) {
      return 'default-table-row'
    },
    handleSearchCondition() {
      let condition = {}
      Object.keys(this.searchCondition).forEach((field) => {
        if (field !== 'date_range' && this.searchCondition[field]) {
          // console.log(Object.prototype.toString.call(this.searchCondition[field]))
          // console.log((this.searchCondition[field]).constructor)
          // console.log(typeof (this.searchCondition[field]))

          condition[field] = this.searchCondition[field]
        }
      })

      if (this.searchCondition.date_range) {
        condition.date_range = this.searchCondition.date_range
      }

      // 分页 +排序
      Object.assign(condition, this.pageData, { orderBy: this.orderBy })

      return condition
    },
    handleQuery() {
      this.pageData.current_page = 1
      this.getList()
      //缓存筛选条件，刷新后可以继续使用
      sessionStorage.setItem(this.pageSearchKey, JSON.stringify(this.searchCondition))
    },
    async getList() {
      this.dataList = []
      this.loading = true
      let params = this.handleSearchCondition()
      let { list, pages } = await this.$api.getRoleLogList(params)
      // this.dataList = [...this.dataList, ...list]
      this.dataList = list
      this.pageData.current_page = pages.current_page || 1
      this.pageData.page_size = pages.page_size || 100
      this.total = pages.total
      this.loading = false
    }
  },
  created() {
    this.searchCondition.info_id = this.RoleId
    // if (sessionStorage.getItem(this.pageSearchKey)) {
    //   this.searchCondition = JSON.parse(sessionStorage.getItem(this.pageSearchKey))
    // }
    this.handleQuery()
  }
}
</script>

<style scoped>

</style>
