<template>
  <div>
    <page-header-layout>
      <div class='main-page-content'>
         <McnBonusView :condition.sync="searchCondition" v-if="searchCondition.dept_id" style="width: 100%"/>
      </div>
    </page-header-layout>
  </div>
</template>

<script>

import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import BonusItemsTable from '@/pages/performance/bonus/BonusItemsTable'
import DeptMcnBonusView from '@/pages/departments/components/DeptMcnBonusView'
import { mapGetters } from 'vuex'
import McnBonusView from "@/pages/team/dept/McnBonusView";

export default {
  name: 'DeptDetail',
  components: {McnBonusView,  DeptMcnBonusView, BonusItemsTable, PageHeaderLayout },
  computed:{
    ...mapGetters(['userPermissions']),
  },
  data() {
    return {
      dept_id: null,
      year: null,
      ym: null,
      deptInfo: {},
      groupBonusData: [],
      pageData: { current_page: 1, page_size: 15 },
      //有权限查看的项目组
      allowGroups:[],
      searchCondition:{year:null,dept_id:null}
    }
  },
  methods: {
  },
  mounted() {
    this.searchCondition.dept_id = this.$route.params.id
    // 默认上个月
    this.searchCondition.year = new Date().getFullYear() + ''
    // this.ym = this.$utils.getLastMonth()
  },
  created() {


  }
}
</script>

<style scoped>

</style>
