<template>
  <div>
    <PageHeaderLayout>
      <div class='main-page-content'>
        <el-row class='table-header'>
          <el-col v-if="buttonType=='icon'">
            <el-tooltip effect='dark' content='添加成员' placement='top-start'
                        v-if="userPermissions.indexOf('roles_users') != -1">
              <el-button type='primary' size='medium' icon='iconfont icon-tianjiacaidan2'
                         @click='addButton(0)'></el-button>
            </el-tooltip>
            <el-tooltip effect='dark' content='返回' placement='top-start'>
              <el-button class='fanhui-button' type='primary' size='medium' icon='iconfont icon-back'
                         @click='returnRolesList'>返回
              </el-button>
            </el-tooltip>
          </el-col>
          <el-col v-if="buttonType=='text'">
            <el-button type='primary' size='medium' v-if="userPermissions.indexOf('roles_users') != -1"
                       @click='addButton(0)'>添加成员
            </el-button>
            <el-button class='fanhui-button' type='primary' size='medium' @click='returnRolesList'>返回</el-button>
          </el-col>
        </el-row>
        <div class='default-table'>
          <ApeTable ref='apeTable' :data='userList' :columns='columns' :loading='loadingStaus' :pagingData='pagingData'
                    @switchPaging='switchPaging' highlight-current-row border>
            <el-table-column
              slot='first-column'
              width='64'
              label='序号'>
              <template slot-scope='scope'>
                <span>{{ offset + scope.$index + 1 }}</span>
              </template>
            </el-table-column>
            <el-table-column
              v-if="buttonType=='icon'"
              label='操作'>
              <template slot-scope='scope'>
                <el-tooltip effect='dark' content='移除成员' placement='top-start'>
                <span>
                  <el-popover
                    v-if="userPermissions.indexOf('roles_users') != -1"
                    placement='top'
                    width='150'
                    v-model='scope.row.visible'>
                    <p>确定移除该用户吗？</p>
                    <div style='text-align: right; margin: 0;'>
                      <el-button type='text' size='mini' @click='scope.row.visible=false'>取消</el-button>
                      <el-button type='danger' size='mini' @click='removeButton(scope.row)'>确定</el-button>
                    </div>
                    <el-button slot='reference' type='danger' size='mini' icon='iconfont icon-yichu-'></el-button>
                  </el-popover>
                </span>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              v-if="buttonType=='text'"
              label='操作'>
              <template slot-scope='scope'>
              <span>
                <el-popover
                  v-if="userPermissions.indexOf('roles_users') != -1"
                  placement='top'
                  width='150'
                  v-model='scope.row.visible'>
                  <p>确定移除该用户吗？</p>
                  <div style='text-align: right; margin: 0;'>
                    <el-button type='text' size='mini' @click='scope.row.visible=false'>取消</el-button>
                    <el-button type='danger' size='mini' @click='removeButton(scope.row)'>确定</el-button>
                  </div>
                  <el-button slot='reference' type='danger' size='mini'>移除成员</el-button>
                </el-popover>
              </span>
              </template>
            </el-table-column>
          </ApeTable>
        </div>

        <div class='box-panel' v-if='userPermissions.indexOf("roles_log")>-1'>
          <el-divider>操作日志</el-divider>
          <RoleLogList :role-id='roleId' v-if='roleId'></RoleLogList>
        </div>

      </div>
    </PageHeaderLayout>

    <ModalDialog :dialog-data='dialogData' @dialogConfirm='handleConfirm' @dialogClose='dialogClose'>
      <template slot='content'>
        <el-form :model='formData' :rules='rules' ref='rolesUserForm' label-position='right' label-width='80px'>
          <el-form-item label='添加模式'>
            <el-switch
              style='display: block'
              v-model='formData.mode'
              inactive-color='#ff4949'
              active-value='user'
              active-text='选择用户'
              inactive-value='dept'
              inactive-text='部门成员'>
            </el-switch>
          </el-form-item>
          <el-form-item label='选择用户' prop='mobile' v-if="formData.mode==='user'">
            <el-autocomplete
              v-model='formData.mobile'
              :fetch-suggestions='searchUser'
              placeholder='输入手机号检索用户'
              @select='handleUserSelect'
              @clear='initMobileVerify'
              clearable>
            </el-autocomplete>
          </el-form-item>
          <div class='dept-member' v-else>
            <el-row :gutter='15'>
              <el-col :span='12'>
                <el-form-item label='部门成员'>
                  <DingTalkDeptSelect ref='refDngTalkDept' @check='getDeptMembers' />
                </el-form-item>
              </el-col>
              <el-col :span='12'>
                <div>
                  <div class='check-all'>
                    <el-checkbox :indeterminate='isIndeterminate' v-model='checkAll' @change='handleCheckAllChange'
                                 style='float: left'>
                      全选({{ checkedMembers.length }}/{{ members.length }})
                    </el-checkbox>
                  </div>
                  <div class='member-list'>
                    <el-checkbox-group v-model='checkedMembers' @change='handleCheckedCitiesChange'>
                      <el-checkbox v-for='member in members' :label='member' :key='member.userid' size='large' border>
                        <el-tooltip :content='member.position' placement='bottom' effect='light'>
                          <span>{{ member.name }} <span>{{ $utils.encryptMobile(member.mobile) }}</span></span>
                        </el-tooltip>
                        <span style='font-weight: lighter'> /
                        <span v-if="member.is_leave==='Y'" style='color: #F56C6C'>{{ member.is_leave_alias }}</span>
                        <span v-else>{{ member.is_leave_alias }}</span>
                      </span>
                      </el-checkbox>
                    </el-checkbox-group>
                  </div>

                </div>
              </el-col>
            </el-row>
          </div>

        </el-form>
      </template>
    </ModalDialog>
  </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import ApeTable from '@/components/ApeTable'
import ModalDialog from '@/components/ModalDialog'
import { mapGetters } from 'vuex'
import DingTalkDeptSelect from '@/pages/departments/components/DingTalkDeptSelect'
import RoleLog from '@/pages/sys_auth/roles/RoleLog'
import RoleLogList from '@/pages/sys_auth/roles/RoleLog'

export default {
  name: 'RoleMembers',
  components: {
    RoleLogList,
    RoleLog,
    DingTalkDeptSelect,
    PageHeaderLayout,
    ApeTable,
    ModalDialog
  },
  data() {
    return {
      roleId: null,
      loadingStaus: true,
      dialogData: {
        visible: false,
        title: '',
        width: '760px',
        loading: true,
        loading_text: '',
        modal: true
      },
      columns: [
        {
          title: '头像',
          value: 'avatar',
          type: 'image',
          width: 60
        },
        {
          title: '昵称',
          value: 'nickname',
          width: 120
        },
        {
          title: '手机号',
          value: 'mobile',
          width: 120
        },
        {
          title: '创建时间',
          value: 'created_at',
          width: 120
        },
        {
          title: '修改时间',
          value: 'updated_at',
          width: 120
        }
      ],
      // 表格列表数据
      userList: [],
      // 分页信息
      pagingData: {
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      },
      // 分页的offset,序号列使用
      offset: 0,
      // 表单结构
      formData: { user_id: '', mode: 'user' },
      // 表单验证
      rules: {
        mobile: [
          { required: true, message: '选择用户', trigger: 'blur', validator: this.autocompleteVerify }
        ]
      },
      checkAll: false,
      checkedMembers: [],
      members: [],
      isIndeterminate: true
    }
  },
  computed: {
    ...mapGetters(['userPermissions', 'buttonType'])
  },
  methods: {
    // 切换页码操作
    async switchPaging() {
      this.initUserList()
    },
    // 响应添加按钮
    async addButton() {
      this.dialogData.visible = true
      this.dialogData.title = '添加成员'
      this.dialogData.loading = false
    },
    // 搜索用户,qs请求参数，cb回调函数，详见组件用法
    async searchUser(qs, cb) {
      let roleId = this.$route.params.role_id
      let list = await this.$api.rolesSearchUser(qs, roleId)
      cb(list)
    },
    // 处理搜索结果的选中
    handleUserSelect(item) {
      this.formData.user_id = item.id
      this.formData.role_id = this.$route.params.role_id
      if (this.formData.user_id) {
        this.$refs['rolesUserForm'].validateField('mobile')
      }
    },
    // 初始化验证
    initMobileVerify() {
      this.formData.user_id = ''
      this.$refs['rolesUserForm'].validateField('mobile')
    },
    // 处理模态框，确定事件
    handleConfirm() {
      // 调用组件的数据验证方法
      this.$refs['rolesUserForm'].validate((valid) => {
        if (valid) {
          this.formSubmit()
        } else {
          this.$message.error('数据验证失败，请检查必填项数据！')
        }
      })
    },
    // 处理模态框，关闭事件
    dialogClose() {
      this.initFormData()
      this.defaultCheckBox()
    },
    // form数据提交，请求接口
    async formSubmit() {
      this.dialogData.loading_text = '玩命提交中……'
      this.dialogData.loading = true
      // 以部门成员的模式批量关联角色的用户
      if (this.formData.mode === 'dept') {
        let userids = this.checkedMembers.map((item) => {
          return item.userid
        })
        this.formData.userids = userids
      }

      //角色ID
      this.formData.role_id = this.$route.params.role_id
      await this.$api.saveRolesUser(this.formData)
      this.$nextTick(() => {
        this.dialogData.visible = false
      })
      this.$nextTick(async () => {
        this.initUserList()
      })
      this.$nextTick(() => {
        this.$message.success('保存成功!')
      })
      this.initFormData()
    },
    // 相应移除按钮
    async removeButton(row) {
      let data = { role_id: this.$route.params.role_id, user_id: row.id }
      let info = await this.$api.removeRolesUser(data)
      if (info == 'ok') {
        this.$nextTick(async () => {
          this.initUserList('del')
        })
      } else {
        this.$message.error(info)
      }
    },
    // 初始化数据
    initFormData() {
      // 初始化form表单
      this.$nextTick(() => {
        this.dialogData.visible = false
        this.dialogData.loading = true
        this.formData = { user_id: '', mode: 'user' }
        this.$refs['rolesUserForm'].resetFields()
      })
    },
    // 初始化用户列表
    async initUserList(type) {
      this.loadingStaus = true
      let pagingInfo = this.$refs['apeTable'].getPagingInfo(type)
      // 实现对量合并
      Object.assign(pagingInfo, this.$route.params)
      let { list, pages } = await this.$api.getRolesUser(pagingInfo)
      this.userList = list
      this.pagingData.total = pages.total
      this.offset = pages.offset
      this.loadingStaus = false
    },
    // 搜索用户，自动补全验证
    autocompleteVerify(rule, value, callback) {
      if (rule.required && (!this.formData.user_id || !this.formData.mobile)) {
        callback(new Error(rule.message))
      }
      callback()
    },
    // 返回角色列表
    returnRolesList() {
      this.$router.push(this.$route.matched[1].path)
    },
    getDeptMembers(data, checkedObj) {
      // let deptIds = this.$refs.refDngTalkDept.getCheckedKeys()
      // let depts = this.$refs.refDngTalkDept.getCheckedItems()
      let depts = checkedObj.checkedNodes
      // let deptNames = depts.map((item) => {
      //   return item.name
      // })
      // this.$notify.success(`所选部门：${deptNames.join('、')}`)
      //根据部门ID查询
      // let checkedKeys = checkedObj.checkedKeys
      this.defaultCheckBox()
      depts.forEach((item) => {
        this.loadMembers({ dpt_id: item.dpt_id })
      })
      // this.loadMembers({dpt_id: group_id})

    },
    async loadMembers(searchCondition) {
      searchCondition.page_size = 50
      let { list, pages } = await this.$api.getDptUsers(searchCondition)
      list.forEach((item) => {
        let index = this.members.findIndex(value => value.userid === item.user_id)
        if (index === -1)
          this.members.push(item)
      })

      searchCondition.page_size = pages.page_size
      searchCondition.current_page = pages.current_page
      for (let i = pages.offset + list.length; i < pages.total; i += pages.page_size) {
        // console.log(`i:${i}`)
        searchCondition.current_page += 1
        let { list } = await this.$api.getDptUsers(searchCondition)
        this.members = [...this.members, ...list]
      }

    },
    defaultCheckBox() {
      this.checkAll = false
      this.checkedMembers = []
      this.members = []
      this.isIndeterminate = true
    },
    handleCheckAllChange(val) {
      this.checkedMembers = val ? [...this.members] : []
      this.isIndeterminate = false
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length
      this.checkAll = checkedCount === this.members.length
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.members.length
    }

  },
  created() {
    this.roleId = this.$route.params.role_id || null
  },
  mounted() {

    this.initUserList()
  }
}
</script>
<style scoped>
.dept-member {
  height: 420px;
  overflow-y: scroll;
  border: #DCDFE6 1px solid;
  padding: 10px;
}

.check-all {
  border: #DCDFE6 1px solid;
  line-height: 20px;
  height: 20px;
  padding: 5px;
  margin-bottom: 5px;
}

.member-list {
  height: 360px;
  overflow-y: scroll;
  border: #DCDFE6 1px solid;
  padding: 10px;
}
</style>

<style lang='stylus'>
.el-button
  margin-right 4px
  margin-bottom 4px

.table-header
  margin-bottom 12px

.drag-handle
  font-size 24px
  cursor pointer

.el-input-group__prepend, .el-input-group__append
  background #ffffff
  padding 0 12px

.el-input-group__append
  color #ffffff
  background #1890ff

.el-popover .el-checkbox-group .el-checkbox
  margin-left 0
  margin-right 12px

.el-select > .el-input
  width 300px

.fanhui-button
  float right
</style>
