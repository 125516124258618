<template>
  <div>
    <el-dialog v-bind="$attrs" v-on="$listeners" @open="onOpen" @close="onClose" title="Dialog Titile">
      <el-form ref="elForm" :model="formData" :rules="rules" size="medium" label-width="100px">
        <el-form-item label="表头名称" prop="title">
          <el-input v-model="formData.title" placeholder="请输入表头名称（头像、KOL、简介）" clearable
                    :style="{width: '100%'}"></el-input>
        </el-form-item>
        <el-form-item label="字段名称" prop="field">
          <el-input v-model="formData.field" placeholder="请输入唯一的字段名称" clearable :style="{width: '100%'}">
          </el-input>
        </el-form-item>
        <el-form-item label="字段前缀" prop="field_prefix">
          <el-input v-model="formData.field_prefix" placeholder="请输入字段前缀" clearable :style="{width: '100%'}">
          </el-input>
        </el-form-item>
        <el-form-item label="字段类型" prop="field_type">
          <el-select v-model="formData.field_type" placeholder="请选择字段类型" clearable :style="{width: '100%'}">
            <el-option v-for="(item, index) in field_typeOptions" :key="index" :label="item.label"
                       :value="item.value" :disabled="item.disabled"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="数据类型" prop="data_type">
          <el-select v-model="formData.data_type" placeholder="请选择数据类型" clearable :style="{width: '100%'}">
            <el-option v-for="(item, index) in data_typeOptions" :key="index" :label="item.label"
                       :value="item.value" :disabled="item.disabled"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="表格列宽" prop="width">
          <el-input v-model="formData.width" placeholder="请输入表格列宽" clearable :style="{width: '100%'}">
          </el-input>
        </el-form-item>
        <el-form-item label="表头高度" prop="height_header">
          <el-input v-model="formData.height_header" placeholder="请输入表头高度" clearable :style="{width: '100%'}">
          </el-input>
        </el-form-item>
        <el-form-item label="表格高度" prop="height_body">
          <el-input v-model="formData.height_body" placeholder="请输入表格高度" clearable :style="{width: '100%'}">
          </el-input>
        </el-form-item>
        <el-form-item label="字体颜色" prop="field109">
          <el-input v-model="formData.field109" placeholder="请输入字体颜色" clearable :style="{width: '100%'}">
          </el-input>
        </el-form-item>
        <el-form-item label="表头背景" prop="color_bg">
          <el-input v-model="formData.color_bg" placeholder="请输入表头背景" clearable :style="{width: '100%'}">
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="close">取消</el-button>
        <el-button type="primary" @click="handelConfirm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "PlcFieldDialog",
  inheritAttrs: false,
  components: {},
  props: [],
  data() {
    return {
      formData: {
        title: undefined,
        field: undefined,
        field_prefix: undefined,
        field_type: undefined,
        data_type: undefined,
        width: undefined,
        height_header: undefined,
        height_body: undefined,
        field109: undefined,
        color_bg: undefined,
      },
      rules: {
        title: [{
          required: true,
          message: '请输入表头名称（头像、KOL、简介）',
          trigger: 'blur'
        }],
        field: [{
          required: true,
          message: '请输入唯一的字段名称',
          trigger: 'blur'
        }],
        field_prefix: [],
        field_type: [{
          required: true,
          message: '请选择字段类型',
          trigger: 'change'
        }],
        data_type: [{
          required: true,
          message: '请选择数据类型',
          trigger: 'change'
        }],
        width: [],
        height_header: [],
        height_body: [],
        field109: [],
        color_bg: [],
      },
      field_typeOptions: [{
        "label": "单行",
        "value": "single"
      }, {
        "label": "多行",
        "value": "multi"
      }],
      data_typeOptions: [{
        "label": "纯文本",
        "value": "text"
      }, {
        "label": "头像图片",
        "value": "avatar"
      }, {
        "label": "数值",
        "value": "number"
      }, {
        "label": "整数",
        "value": "int"
      }, {
        "label": "百分比",
        "value": "percentage"
      }],
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    onOpen() {},
    onClose() {
      this.$refs['elForm'].resetFields()
    },
    close() {
      this.$emit('update:visible', false)
    },
    handelConfirm() {
      this.$refs['elForm'].validate(valid => {
        if (!valid) return
        this.close()
      })
    },
  }
}

</script>
<style>
</style>
